import React, { useEffect, useState } from 'react';
import ListCard from '../../component/dashboard/list_Card';
import Header from '../../component/dashboard/header';
import { Row, Col, Breadcrumb, message, Popover, Select, Collapse, Button } from 'antd';
import { UserOutlined, SearchOutlined, LogoutOutlined, FilterFilled, LoadingOutlined, CloseOutlined, CaretDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import User from '../../component/dashboard/userCard';
import Space from '../../component/dashboard/space';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom'
import { catalog, search, contributors, subjects ,bookcover,orgsearch} from '../../api/api'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setshowlogin ,setorg} from '../../redux/actions/dashboardActions';
import Offline from '../error/offline';
import Error500 from '../error/500';
const { Panel } = Collapse;

const Allcollection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const showuser = useSelector((state) => state.App.showlogin);
  const [isLoading, setIsLoading] = useState(true)
  const [isloading, setisloading] = useState(false)
  const location = useLocation();
  const orgdetails = location.state.org;
  const ismuliple = location.state.mutiple
  const [data, setdata] = useState([])
  const [emptyBooks, setEmptyBooks] = useState([{}, {}, {}, {}, {}, {}, {}, {}])
  const [visible, setvisible] = useState(false)
  const emptyData = [{}, {}, {}, {}, {}]
  const [showfilter, setshowfilter] = useState(false)
  const [text, settext] = useState("")
  const [subtext, setsubtext] = useState("")
  const [authtext, setauthtext] = useState("")
  const [colltext, setcolltext] = useState("")
  const [sort, setsort] = useState("Newest First")
  const [author, setAuthor] = useState([])
  const [authorfill, setAuthorfil] = useState([])
  const [subject, setSubject] = useState([])
  const [subjectfill, setSubjectfill] = useState([])
  const [collection, setCollection] = useState([])
  const [collectionfill, setCollectionfil] = useState([])
  const [subcheckedList, setSubCheckedList] = useState([]);
  const [autcheckedList, setAutCheckedList] = useState([]);
  const [collList, setCollCheckedList] = useState([]);
  const [searchlist, setSearchlist] = useState([]);
  const [error, setError] = useState(false)
  const isOnLine=localStorage.getItem('isonline')
  useEffect(() => {
    localStorage.setItem('lpporg', JSON.stringify(orgdetails));

    window.scrollTo(0, 0)
    fetchDashboardData()
    getContributors()
    getSubjects()

  }, [])

  const handleVisibleChange = () => {
    dispatch(setshowlogin(!showuser))
  };

  const fetchDashboardData = async () => {
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));

    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: catalog,
      // headers: {
      //   'apikey': lpp_userCredentials.apikey
      // }


    };
    axios(config)
      .then((response) => {
        setdata(response.data)
        setCollection(response.data.compendiums)
        setCollectionfil(response.data.compendiums)
        setIsLoading(false);
      })
      .catch(function (error) { console.log(error);setError(true)      });
  }
  const goToList = (titel) => {
    history.push('/list')
  }
  const logout = () => {
    localStorage.removeItem('lpp_userCredentials')
    history.replace('/');
  }
  const updatetext = event => {
    settext(event.target.value)
  }

  const getContributors = () => {
    let url = contributors;
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(url, requestOptions,)
      .then(response => response.json())
      .then(result => {
        setAuthor(result.data)
        setAuthorfil(result.data)

      })
      .catch(error => {
      });

  }
  const getSubjects = () => {
    let url = subjects;
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(url, requestOptions,)
      .then(response => response.json())
      .then(result => {
        setSubject(result)
        setSubjectfill(result)

      })
      .catch(error => {
      });

  }
  const onClear = () => {
    settext("")
    setSearchlist([])

}
  const handleSelectsub = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSubCheckedList([...subcheckedList, value]);

    } else {
      const filteredList = subcheckedList.filter((item) => item !== value);
      setSubCheckedList(filteredList);

    }
  };
  useEffect(() => { // this hook will get called every time myArr has changed
    if(!isLoading){
       getBooksList()
  
     }
   
 }, [subcheckedList])
  const handleSelectauth = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setAutCheckedList([...autcheckedList, value]);

    } else {
      const filteredList = autcheckedList.filter((item) => item !== value);
      setAutCheckedList(filteredList);

    }
  };
  useEffect(() => { // this hook will get called every time myArr has changed
   
    if(!isLoading){
      getBooksList()
  
     }
  
 }, [autcheckedList])

 useEffect(() => { // this hook will get called every time myArr has changed
   
  if(!isLoading){
    getBooksList()

   }

}, [sort])

  const handleSelectcoll = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setCollCheckedList([...collList, value]);
      getBooksList()
    } else {
      const filteredList = collList.filter((item) => item !== value);
      setCollCheckedList(filteredList);
      getBooksList()

    }
  };
  useEffect(() => { // this hook will get called every time myArr has changed
   if(!isLoading){
    getBooksList()

   }

 }, [collList])

  const getBooksList = async () => {
    console.log(autcheckedList,subcheckedList,collList)
    setisloading(true)
    // const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
    message.destroy()
    let url = orgsearch+orgdetails.fuzzyId+"/api?q="+text+"&sort="+sort;
    if(autcheckedList.length>0){
      url=url+"&author="+autcheckedList.toString()
    }
    if(subcheckedList.length>0){
      url=url+"&subject="+subcheckedList.toString()
    }
    if(collList.length>0){
      url=url+"&collection="+collList.toString()
    }

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      // headers: {
      //   'apikey': lpp_userCredentials.apikey
      // }
    };

    fetch(url, requestOptions,)
      .then(response => response.json())
      .then(result => {
        if (result.pagination.count==0) {
          message.warning('No Data Found');
          setisloading(false)

        } else {
          setSearchlist(result.data)
          setisloading(false)

        }

      })
      .catch(error => {
        setisloading(false)

      });

  }

  const handleChange = (value) => {
    setsort(value)
  };

  const searchSub = (event) => {
    setsubtext(event.target.value)
    console.log(event.target.value)
    let result = subjectfill.filter(o => o.name.toLowerCase().includes(event.target.value.toLowerCase()));
    setSubject(result)

  }
  const searchAuth = (event) => {
    setauthtext(event.target.value)
    let result = authorfill.filter(o => o.name.toLowerCase().includes(event.target.value.toLowerCase()));
    setAuthor(result)

  }
  const searchColl = (event) => {
    setcolltext(event.target.value)
    let result = collectionfill.filter(o => o.name.toLowerCase().includes(event.target.value.toLowerCase()));
    setCollection(result)

  }
  const trancateTitle= (title)=> {
    try{
        var length = 20;
        if (title.length > length) {
           title = title.substring(0, length)+'...';
        }
        return title;
    }catch{
        return title;

    }
    
}

if(isOnLine=="offline"){
  return(
    <div>
    <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
        <Header org={true} />

        <div className="dashboard">

         <Offline/>
        </div>


      </Col>
    </Row>

  </div>
  )
}


if (error) {
  return (
    <div>
    <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
        <Header org={true} />

        <div className="dashboard">

         <Error500/>
        </div>


      </Col>
    </Row>

  </div>
  )
}

  if (isLoading) {
    return (
      <div>
        <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
            <Header org={true} />

            <div className="dashboard">

              <Space hig="10px" color="#fff" />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " titel="openaccessbooks" data={emptyData} isBook={true} isLoading={true} access={"free"} />
              <Space hig="1px" color="#ECF0EF" />
              <ListCard cardtitel=" " data={emptyData} isBook={true} isLoading={true} access={"free"} />
              <Space hig="1px" color="#ECF0EF" />
              <Space hig="60px" color="#fff" />
            </div>


          </Col>
        </Row>

      </div>
    )
  }

  return (
    <div className="dashboard">
      <Header org={true} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px', paddingLeft: '5px', paddingRight: '5px' }}>
        <p style={{ fontWeight: "bold", letterSpacing: '1px', marginTop: '10px', }} className="pageheadertitle">{ismuliple && <ArrowLeftOutlined onClick={() => { history.goBack() }} />}<span style={{ marginLeft: '2px' }}>{orgdetails.name}</span></p>
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          {/* <Popover visible={showuser}
            trigger="click"
            onVisibleChange={handleVisibleChange} content={<User hidepop={() => setvisible(false)} />} >
            <div className="profile">
              <UserOutlined style={{ color: "#F36C23", marginRight: '10px' }} className="menuicon" />
            </div>
          </Popover> */}
          <LogoutOutlined  onClick={logout} style={{ color: "#F36C23", marginRight:'10px' }} className="menuicon" />
        </div>

      </div>
      <div style={{ display: "flex", padding: '10px', backgroundColor: '#D9D9D9', alignItems: 'center', justifyContent: 'center', position: 'relative', paddingBottom: '15px', flexDirection: 'column', }}>
        <div className="searchinput" style={{ backgroundColor: '#fff', marginRight: '0px' }}>
          <div className='searchbox'>
            <input type="search" className="searchboxfiled" placeholder="search..." name="search" value={text} onChange={updatetext} />
            {text &&  <CloseOutlined onClick={onClear} className='clearicon' style={{marginRight:'10px' ,fontSize:'15px'}} />}
            {isloading ? <div className='searchicon' style={{ backgroundColor: '#F36C23'}}> <LoadingOutlined style={{color: '#fff',}}  /></div> : <SearchOutlined onClick={getBooksList} className='searchicon' style={{ backgroundColor: '#F36C23', color: '#fff',}} />}                   

            {/* <SearchOutlined onClick={getBooksList} className='searchicon' style={{ backgroundColor: '#F36C23', color: '#fff',}} /> */}
          </div>
        </div>
        <div className='fliterdiv'>
          <p className='filter-titel'>Filter your results</p>

          <p className='sort'>Sort by<Select
            defaultValue="Newest First"
            suffixIcon={<CaretDownOutlined />}
            bordered={false}
            className='lable-filter'
            style={{
              backgroundColor: "#D9D9D9"
            }}
            onChange={handleChange}
            options={[
              {
                value: 'Newest First',
                label: 'Newest First',
              },
              {
                value: 'Oldest First',
                label: 'Oldest First',
              },


            ]}
          /></p>

        </div>
        
        {showfilter && <Collapse style={{ width: '100%', }} expandIconPosition={["right"]}   >
          <Panel header="Subject" key="1" className="panal">
            <div style={{ overflow: 'scroll', height: '150px' }}>
              <div className="searchinput" style={{ marginRight: '0px', marginBottom: '5px' }}>
                <div className='searchbox'>
                  <input type="search" className="searchboxfiled" placeholder="search..." name="search" value={subtext} onChange={searchSub} />
                  <SearchOutlined  className='searchicon' style={{ color: '#000', }} />
                </div>
              </div>
              {subject.map((item, index) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                  <input type="checkbox" value={item.fuzzyId} onChange={handleSelectsub} />
                  <p className='lable-filter-sub'>{item.name}</p>
                </div>
              ))}
            </div>

          </Panel>
          <Panel header="Collection" key="2" className="panal">
            <div style={{ overflow: 'scroll', height: '150px' }}>
            <div className="searchinput" style={{ marginRight: '0px', marginBottom: '5px' }}>
                <div className='searchbox'>
                  <input type="search" className="searchboxfiled" placeholder="search..." name="search" value={colltext} onChange={searchColl} />
                  <SearchOutlined  className='searchicon' style={{ color: '#000', }} />
                </div>
              </div>
              {collection.map((item, index) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                  <input type="checkbox" value={item.compendiumFuzzyId} onChange={handleSelectcoll} />
                  <p className='lable-filter-sub'>{item.name}</p>
                </div>
              ))}
            </div>
          </Panel>
          <Panel header="Author" key="3" className="panal">
            <div style={{ overflow: 'scroll', height: '150px' }}>
            <div className="searchinput" style={{ marginRight: '0px', marginBottom: '5px',}}>
                <div className='searchbox'>
                  <input type="search" className="searchboxfiled" placeholder="search..." name="search" value={authtext} onChange={searchAuth} />
                  <SearchOutlined  className='searchicon' style={{ color: '#000', }} />
                </div>
              </div>
              {author.map((item, index) => (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline',}}>
                  <input type="checkbox"  value={item.uniqueName} onChange={handleSelectauth} />
                  <p className='lable-filter-sub'>{item.surName +" "+item.givenName}</p>
                </div>
              ))}
            </div>
          </Panel>
         
        </Collapse>}
        {showfilter ? <CloseOutlined onClick={() => { setshowfilter(false)}} className="filtericon" /> : <FilterFilled onClick={() => { setshowfilter(true) }} className="filtericon" />}
      </div>
     {searchlist.length>=1? <Row className="booklist" style={{marginTop:'10px',}}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 1 }}>
                <div className='list-result'>
                  <p className='resulttext'>Results({searchlist.length})</p>
                  <p className='cleartext' onClick={() => { setshowfilter(false);setSearchlist([]) }}><span style={{marginRight:'2px'}}>Clear</span><CloseOutlined/></p>

                  </div>

                    <Row>
                        {searchlist.map(item => (
                            <Col className="journalList" xs={12} sm={8} md={6} lg={4} xl={4}>

                                <div onClick={() => {history.push({pathname:'/institutions/book/isbn/'+ item.identifier,state:{contentid:item.contentId,fuzzyid:item.fuzzyId}})}} className="journalCover" style={{ backgroundImage: `url(${bookcover + item.fuzzyId + "/"+item.cover})`, backgroundColor: "#EEFBF9" }}>
                                </div>
                                <p className='ctitel'>{trancateTitle(item.name)}</p>

                            </Col>
                        ))}
                       
                    </Row>

                </Col>
            </Row>: <>
      <>
        <Space hig="1px" color="#ECF0EF" />

        <ListCard isint={true} cardtitel={"Recent Books"} isname={true} data={data.recentBooks} titel={"recentBooks"} isBook={true} access={"paid"} isLoading={isLoading} />
        <Space hig="1px" color="#ECF0EF" />
      </>
      {data.categories.map((item) =>
        <>
          <Space hig="1px" color="#ECF0EF" />

          <ListCard isint={true} cardtitel={item.name} data={item.contents} titel={item.compendiumFuzzyId} isBook={true} access={"paid"} isLoading={isLoading} />
          <Space hig="1px" color="#ECF0EF" />
        </>
      )}
      <>
        <Space hig="1px" color="#ECF0EF" />

        <ListCard isint={true} cardtitel={"Forthcoming Books"} isname={true} data={data.upcomingBooks} titel={"upcomingBooks"} isBook={true} access={"paid"} isLoading={isLoading} />
        <Space hig="1px" color="#ECF0EF" />
      </>
        </>}
      


    </div>
  );
}

export default Allcollection;
