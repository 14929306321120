import React, {useEffect,useState } from 'react';
import ListCard from '../../component/dashboard/colllist_Card';
import { Row, Col} from 'antd';
import Space from '../../component/dashboard/space';
import{useHistory}from 'react-router-dom'
import {catalog} from '../../api/api'
import Error500 from '../error/500';
import Offline from '../error/offline';

const Allcollection = () => {
  const history=useHistory();
  const [isLoading,setIsLoading]=useState(false)
  const emptyData = [{}, {}, {}, {}, {}]
  const [data,setdata]=useState([])
  const [emptyBooks,setEmptyBooks]=useState([{},{},{},{},{},{},{},{}])
  const [error, setError] = useState(false)
  const isOnLine=localStorage.getItem('isonline')

  useEffect(() => {
    window.scrollTo(0, 0)
    
           fetchDashboardData()            
      
   
  }, [])

 
  const fetchDashboardData = async () => {
    setIsLoading(true)
    let axios = require('axios');
    let config = {
      method: 'get',
      url: catalog,
      
    };
     axios(config)
    .then((response)=>{
        setdata(response.data.compendiums)

      setIsLoading(false);
    })
    .catch(function (error) {       setIsLoading(false);
      setError(true) });
   }
  const goToList = (titel) => {
    history.push('/list')
  }
  if(isLoading){
    return(
      <div>
      <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" ,marginTop: '10px'}}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>


            <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
            <Space hig="1px" color="#ECF0EF" />
            <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
            <Space hig="1px" color="#ECF0EF" />
            <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
            <Space hig="1px" color="#ECF0EF" />
            <ListCard cardtitel=" " titel="openaccessbooks" data={emptyData} isBook={true} isLoading={true} access={"free"} />
            <Space hig="1px" color="#ECF0EF" />
            <ListCard cardtitel=" " data={emptyData} isBook={true} isLoading={true} access={"free"} />
            <Space hig="1px" color="#ECF0EF" />
            <Space hig="60px" color="#fff" />


        </Col>
      </Row>

    </div>
    )
  }
  if(isOnLine=="offline"){
    return(<Offline/>)
  }
  if (error) {
    return (
      <Error500 />
    )
  }
 
  return (
    <div>
       <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px", marginTop: '10px' }}>
       <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
   <>
    {data.map((item)=>
    <>

    <ListCard isint={false} isname={true} cardtitel={item.name} data={item.contents} titel={item.compendiumFuzzyId} isBook={true} access={"paid"} isLoading={isLoading} />
    <Space hig="1px" color="#ECF0EF"/>
    </>
    )}
        <Space hig="60px" color="#fff"/>
        </>
        </Col>
     </Row>
     
     </div>
  );
}

export default Allcollection;
