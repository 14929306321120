import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfURL, bookcover,articlepdfURL } from '../../api/api';
import PDFViewer from '../../component/PDFViewer/PDFViewer';
import PDFJSBackend from '../../backends/pdfjs';
import Header from '../../component/pageheader/pageHeader';
import { Spin, Col, Row,Breadcrumb,message,Progress} from 'antd';
import { useIndexedDB } from 'react-indexed-db';
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { useHistory,useLocation } from 'react-router-dom'
import Bookcover from '../../api/images/book.png';
import Axios from "axios";

const Pdf = (props) => { 
  const history = useHistory();
  const nlocation = useLocation();
  const contentid=nlocation.state.contentid
  const fuzzyid= nlocation.state.fuzzyid
  const isbn=nlocation.state.isbn
  const titel=nlocation.state.name
  const elasticid=props.match.params.elasticid
  const isonline=nlocation.state.isonline
  const type=nlocation.state.type
  const cover=nlocation.state.cover
  const isorg=nlocation.state.isorg
  const bookinfo=nlocation.state.info
  const author=nlocation.state.auth
  const org = JSON.parse(localStorage.getItem('lpporg'));

  const { add, getByIndex } = useIndexedDB('book');
  const [location, setlocation] = useState('')
  const [loading, setloading] = useState(false)
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
 const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  useEffect(() => {
    setloading(true)
    let name=titel;
    if(isonline=="yes"){
      name=titel+ "-pdf"
    }else{
      name=titel
    }
    getByIndex('name', name).then(file => {
      if (file) {
        const url = window.URL.createObjectURL(new Blob([file.file]));
        localStorage.setItem('pdfurl', url);
        setlocation(url)
        setloading(false)
      } else {
        const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
        if(lpp_userCredentials){
          getdata(lpp_userCredentials.apikey);

        }else{
          getdatano()
        }
      }
    });


  }, [])
  // const getdata = (apikey) => {
  //   setloading(true)
  //   let url=pdfURL;
  //   if(type=="article"){
  //     url=articlepdfURL
  //   }
  //   var myHeaders = new Headers();
  //   myHeaders.append("apikey", apikey);
  //   myHeaders.append("Content-Type", "appl");
  //   var requestOptions = {
  //     method: 'GET',
  //     headers: myHeaders,
  //   };
  //   fetch(url + elasticid, requestOptions)
  //     .then(response => response.blob())
  //     .then(file => {
  //           add({ name: titel + "-pdf", file: file, img: bookcover + fuzzyid + "/"+cover, type: "pdf" }).then(
  //             event => {
  //               const url = URL.createObjectURL(new Blob([file]))
  //               localStorage.setItem('pdfurl', url);
  //               setlocation(url)
  //               setloading(false)
  //             },
  //             error => {
  //               setloading(false)
  //            }
  //           );
  //         })

    

  //     .catch(error => console.log('error', error));
  // }
  const getdata = (apikey) => {
    message.success('Downloading ....');
    setloading(true)
    let url=pdfURL+elasticid;
    if(type=="article"){
      url=articlepdfURL+elasticid
    }
    if(isorg){
      url="https://livedplacespublishing.com/downloads/institutions/"+org.fuzzyId+"/book/webpdf/"+elasticid
    }
   
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    Axios.get(url, {responseType: "blob",headers: {'apikey': apikey,'Content-Type': "appl"}, ...options})
    .then (response => response.data)
      .then(async (result) => {
        console.log(result)
        
        if ('arrayBuffer' in result) return await result.arrayBuffer();
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsArrayBuffer(result);
    });
     }).then(file=>{
      Axios.get(bookcover + fuzzyid + "/"+cover, {responseType: "blob"})
       .then(response =>{
        add({ name:titel+"-pdf",file:file,img:response.data,type:"pdf",info:bookinfo,auth:author }).then(
          event => {
            const url = URL.createObjectURL(new Blob([file]))
              localStorage.setItem('pdfurl', url);
            setlocation(url)
            setloading(false)
          },
          error => {
            console.log(error);
            setlocation(url)
            setloading(false)
          }
        );
      })

    }) 
      .catch(error => {
        message.error('Sorry, something went wrong try again !.');
        setTimeout(() => {
          history.goBack();
        }, 3000);
       });
  }
  const getdatano = (apikey) => {
    message.success('Downloading ....');
    setloading(true)
    let url=pdfURL+elasticid;
    if(type=="article"){
      url=articlepdfURL+elasticid
    }
    if(isorg){
      url="https://livedplacespublishing.com/downloads/institutions/"+org.fuzzyId+"/book/webpdf/"+elasticid
    }
   
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    Axios.get(url, {responseType: "blob", ...options})
    .then (response => response.data)
      .then(async (result) => {
        console.log(result)
        
        if ('arrayBuffer' in result) return await result.arrayBuffer();
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject;
        reader.readAsArrayBuffer(result);
    });
     }).then(file=>{

              add({ name:titel+"-pdf",file:file,img:bookcover + fuzzyid + "/"+cover,type:"pdf",info:bookinfo,auth:author }).then(
              event => {
                const url = URL.createObjectURL(new Blob([file]))
                  localStorage.setItem('pdfurl', url);
                setlocation(url)
                setloading(false)
              },
              error => {
                console.log(error);
                setlocation(url)
                setloading(false)
              }
            );
           
            
      }) 
      .catch(error => {
        message.error('Sorry, something went wrong try again !.');
        setTimeout(() => {
          history.goBack();
        }, 3000);
       });
  }


if (loading) {
    return (
      <div>
 <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">{" "}</Breadcrumb.Item>
             </Breadcrumb>              <Row>
                <Col style={{ height: '95vh', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center' }}>
                  <p style={{ letterSpacing: '1px', marginTop: '10px',fontFamily:'var(--font)' }}>{titel}</p>
                  <img src={bookcover + fuzzyid + "/"+cover} style={{ height: '80%', width: '100%', backgroundColor: '#cccccc' }} alt="" loading="lazy" />
                  <div style={{ height: '20%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', backgroundColor: "#ffffff",flexDirection:'column',padding:'10px' }}>
                    <Progress percent={downloadInfo.progress} size="default" status="active"  />
                    <p style={{ color: '#000', letterSpacing: '1px', marginTop: '10px', marginLeft: '10px',fontFamily:'var(--font)' }}><span style={{ color: '#1890ff',}}> {formatBytes(downloadInfo.loaded)}</span> / {formatBytes(downloadInfo.total)}</p>
                  </div>
                </Col>
              </Row>
            </div>
    )
  }
  return (
    <PDFViewer
      backend={PDFJSBackend}
      src={location}
    />
  )
}

export default Pdf;