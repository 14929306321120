import React, { useState } from "react";
import { Form, Input, Button, Checkbox,Modal,message } from 'antd';
import { userLogin, resendMail } from '../../api/api';
import{useHistory}from 'react-router-dom'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import validator from 'validator';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
const NormalLoginForm = () => {
  const history=useHistory();
  const [loadings, setloadings] = useState(false);
  const [btnText, setbtnText] = useState("Login");


  const onFinish = async (values) => {
    if(validator.isEmail(values.username)){
      setloadings(true);
      setbtnText("Loading")
     let formdata = new FormData();
      formdata.append("userName", values.username.toLowerCase());
      formdata.append("password", values.password);
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      fetch(userLogin, requestOptions)
        .then(response => response.json())
        .then(async (result) => {
          console.log(result)
          const loginRes = await checkLogin(result);
          if (loginRes == true) {
            history.replace("/")
         
          } else {
            setloadings(false);
            setbtnText("Login")
           
          }
        })
        .catch(error =>{
          setloadings(false);
          message.error("Invalid Email")

        });
    }else{
       message.error("Invalid Email")
    }
  

  }
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const  checkLogin = async (result) => {
   if (result.cred === 0) {
      Modal.error({
        title: 'Login failed',
        content: 'Incorrect password. Please check given credentials.',
      });
    }
    else if (result.cred === 2) {
      Modal.warning({
        title: 'Invalid Email-ID',
        content: 'Your email address is not registered.',
      });
    }
    else if (result.cred === 3) {
      Modal.warning({
        title: 'Login Failed',
        content: 'Incorrect email or password. Please try again.',
      });
    }
    else if (result.cred === 1) {
      let user_details = {
        apikey: result.apikey,
        usermail: result.email,
        email: result.username,
        emailVerified: result.emailVerified || result.emailverified,
        displayName: result.displayName,
        fuzzyId:result.fuzzyId,
        _id: result._id,
        guser: 0
      };
      if (user_details['emailVerified'] === 0 || user_details['emailVerified'] === undefined) {
        let homePage = await getEmailVerification(user_details.email);
        console.log("finalresponse", homePage)
        if (homePage.status === 202) {
          {
            Modal.info({
              title: 'Verification',
              content: 'A verification link has been sent to your email address, please click on the link to activate your account.',
            });
          }
        }
      }
      else {
       
        localStorage.setItem('lpp_userCredentials', JSON.stringify(user_details));
        return true;
      }
    }
    else {
      Modal.warning({
        title: 'Login Failed',
        content: 'Oops error, Please try again',
      });
    };
  }
  const getEmailVerification = async (usermail) => {
    var formdata = new FormData();
    formdata.append("email", usermail);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    let data = await fetch(resendMail, requestOptions);
    let result = await data.json();
    return result
  }


  return (
    <div>
      <Form  name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          //  label="Email"
          name="username"
          
          rules={[{required: true, message: 'Please input your username!' }]}
        >
          <Input placeholder="Email"  />
        </Form.Item>

        <Form.Item
          // label="Password"
          name="password"
          rules={[{required: true, message: 'Please input your password!' }]}
        >
         <Input.Password placeholder="Password"/>
        </Form.Item>

          <Form.Item style={{ "clear": "both",marginTop:'30px'}}>
          <Button className="loginBtn"  type="primary" htmlType="submit" block loading={loadings}>
          <span className="btntext">  {btnText}</span>
          </Button>
        </Form.Item>
      </Form>

    </div>
  );

};

export default NormalLoginForm;