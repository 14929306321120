import Logo from './images/vijay.png';
export const SERVER_URL = "https://www.vijaynicole.co.in/";
export const APP_NAME="Vijaynicole";
export const LOGO=Logo;
export const FbLoginID=365059365513232;
export const AppleClientId="co.novareader.researchpad";
export const GmailClientId="343724709682-7k9o0e0dhrv2ee0koa28gcumplv6a5ud.apps.googleusercontent.com"
export const OrcidClientId="https://orcid.org/oauth/authorize?client_id=APP-NE6GOPUF09930F9B&response_type=code&scope=/authenticate&redirect_uri=https://mobile.vijaynicole.co.in/login"
export const URL="https://mobile.vijaynicole.co.in/";
export const AppVersion="6";
export const RedirectUri="https://www.vijaynicole.co.in/api/applelogin"
export const Cover="https://storage.googleapis.com/nova-vijaynicole-unsecured-files/unsecured/";





