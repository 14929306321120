export const DBConfig = {
    name: 'file',
    version: 4,
    objectStoresMeta: [
      {
        store: 'epub',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'file', keypath: 'file', options: { unique: true } },
          { name: 'img', keypath: 'img', options: { unique: false } },
          { name: 'type', keypath: 'type', options: { unique: false } }
         
        ]
      },
      {
        store: 'book',
        storeConfig: { keyPath: 'id', autoIncrement: true },
        storeSchema: [
          { name: 'name', keypath: 'name', options: { unique: false } },
          { name: 'file', keypath: 'file', options: { unique: true } },
          { name: 'img', keypath: 'img', options: { unique: false } },
          { name: 'type', keypath: 'type', options: { unique: false } },
          { name: 'info', keypath: 'info', options: { unique: false } },
          { name: 'auth', keypath: 'auth', options: { unique: false } }
        ]
      },
    ]
  };