import React from 'react';
import { useHistory } from 'react-router-dom'
import { FiSearch} from "react-icons/fi";
import { BiArrowBack} from "react-icons/bi";
import './styles.css'

const PageHader=(props)=>{
    const history = useHistory();

    return(
        <div className="headerbar">
        <div onClick={()=>{history.goBack()}}  className="gobackicon">
        <BiArrowBack  className='backicon-page'/>
        </div>
        <div className="bartitel">
            <span className="pageheadertitel">  {props.titel}</span>
        </div>
        <div className="menucontiner">
      <div className="profile" onClick={() => history.push('/search') }>
     {!props.hideicon && <FiSearch  className='searchicon-page' />}
          </div>
      
       
      </div>
    </div>
    )
}

export default PageHader;