import React, { useState, useEffect } from 'react';
import Header from '../../component/dashboard/header';
import './styles.css';
import Downloaded from './downloaded';
import 'antd-mobile/dist/antd-mobile.css';
import { Row, Col,Breadcrumb} from 'antd';
import { useHistory } from 'react-router-dom'
import {LoadingOutlined,ArrowLeftOutlined} from '@ant-design/icons';


const Downloadedpage = () => {
    const history = useHistory();

     
  

  
    
   
    return (
        <div>
            <Header org={true}/>
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">Downloads</Breadcrumb.Item>
             </Breadcrumb>
            <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
                   
                  
                        <Downloaded/>
                    
                </Col>
            </Row>
        </div>

    )
}

export default Downloadedpage;