import React, {useEffect,useState } from 'react';
import{useHistory}from 'react-router-dom'
import {catalog} from '../../api/api'
import {ArrowRightOutlined,BankOutlined} from '@ant-design/icons';
import Header from '../../component/dashboard/header';
import {Row, Col,} from 'antd';
import { organization } from '../../api/api';
import ListCard from '../../component/dashboard/list_Card';
import Space from '../../component/dashboard/space';
import Offline from '../error/offline';
import Error500 from '../error/500';

const Collections = () => {
  const history=useHistory();
  const [isLoading,setIsLoading]=useState(true)
  const [data,setdata]=useState([])
  const [org, setorg]=useState([])
  const emptyData=[{}, {}, {}, {}, {}]
  const [error, setError] = useState(false)
  const isOnLine=localStorage.getItem('isonline')
  useEffect(() => {
    window.scrollTo(0, 0)
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
        if (lpp_userCredentials) {
          fetchDashboardData()            

        }else{
          fetchDashboardDatano();
        }

    // const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));
    // if (lpp_userCredentials != null) {
    //     if (lpp_userCredentials.emailVerified != 1) {
    //         history.push('./login');
    //     } else {
    //        fetchDashboardData()            
    //     }
    // }
    // else {
    //     history.push('./login');
    // }
   
  }, [])
 
  const fetchDashboardData = async () => {
    setIsLoading(true)
    const lpp_userCredentials = JSON.parse(localStorage.getItem('lpp_userCredentials'));

        let axios = require('axios');
        let config = {
            method: 'get',
            url: organization,
            headers: {
                'apikey': lpp_userCredentials.apikey
            }
        };
        axios(config)
            .then((response) => {
                if(response.data.data.length==0){
                }else if(response.data.data.length==1){
                  history.push({pathname:"/institution",state:{org:response.data.data[0],mutiple:false}})
                }
                else{

                    setorg(response.data.data)
                    setIsLoading(false)

    
                }
            })
            .catch(function (error) {

              setIsLoading(false)
              setError(true)
            });
   }
   const fetchDashboardDatano = async () => {
    setIsLoading(true)

        let axios = require('axios');
        let config = {
            method: 'get',
            url: organization,
            
        };
        axios(config)
            .then((response) => {
                if(response.data.data.length==0){
                }else if(response.data.data.length==1){
                  history.push({pathname:"/institution",state:{org:response.data.data[0],mutiple:false}})
                }
                else{

                    setorg(response.data.data)
                    setIsLoading(false)

    
                }
            })
            .catch(function (error) {

              setIsLoading(false)
              setError(true)
            });
   }

   if(isOnLine=="offline"){
    return(
      <div>
      <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
          <Header org={true} />
  
          <div className="dashboard">
  
           <Offline/>
          </div>
  
  
        </Col>
      </Row>
  
    </div>
    )
  }
  
  
  if (error) {
    return (
      <div>
      <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
          <Header org={true} />
  
          <div className="dashboard">
  
           <Error500/>
          </div>
  
  
        </Col>
      </Row>
  
    </div>
    )
  }
if(isLoading){
  return(
    <div>
    <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
        <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
             <Header org={true}/> 
           
             <div className="dashboard">
   
  <Space hig="10px" color="#fff"/>
  <Space hig="1px" color="#ECF0EF"/>
  <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
  <Space hig="1px" color="#ECF0EF"/>
  <ListCard cardtitel=" " data={emptyData} isBook={true} access={"paid"} isLoading={true} />
  <Space hig="1px" color="#ECF0EF"/>
  <ListCard cardtitel=" "  data={emptyData} isBook={true} access={"paid"} isLoading={true} />
  <Space hig="1px" color="#ECF0EF"/>
  <ListCard cardtitel=" " titel="openaccessbooks"  data={emptyData} isBook={true} isLoading={true} access={"free"} />
  <Space hig="1px" color="#ECF0EF"/>
  <ListCard cardtitel=" "  data={emptyData} isBook={true} isLoading={true} access={"free"} />
  <Space hig="1px" color="#ECF0EF"/>
  <Space hig="60px" color="#fff"/>
 </div>
                    
                    
           </Col>
    </Row>
  
  </div>
  )
}
 
  return (
    
    
<Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}> 
<Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

  <Header org={true}/>
  <div className="coll-continer">

        <div className='coll-body'>
        
                <p className='inthome-titel'>Choose the library from the list</p>
                <div className='line'></div>
                
         {org.map((item)=>
          <div onClick={()=>{history.push({pathname:"/institution/",state:{org:item,mutiple:true}})}} className='int-card'>
                <BankOutlined  style={{fontSize:"18px",marginLeft:'0px',marginBottom:'15px',color:'#f67225',fontWeight:'bold'}}/>
                 <p className='orgname'>{item.name}</p>
                 <ArrowRightOutlined style={{fontSize:"15px",marginLeft:'0px',color:'#F36C23'}}/>

          </div>
         )} 
        </div>
     
     </div>
     </Col>
     </Row>

    
     
  );
}

export default Collections;
