import React, { useState,useEffect } from "react";
import { Result,Button } from 'antd';
import{useHistory}from 'react-router-dom'

const Offline=(props)=> {
    const history=useHistory();
 return (
        <div style={{display: 'flex', height:'50%', width: '100%', alignItems: "center", justifyContent: 'center',marginTop:props.mtop? props.mtop:'100px'}}>
        <Result
            status="500"
            title=""
            subTitle="You're offline.Explore downloads?"
            extra={<Button onClick={()=>history.push('/downloaded')} type="primary">Go to Downloads</Button>}/> 
        </div>
    );
  
}

export default Offline;
